







































import Vue from 'vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
// eslint-disable-next-line import/no-cycle
import QuizApi from '@/apis/QuizApi';
import Status from '@/apis/models/RespondStatus';

export default Vue.extend({
  components: {
    LanguageSelector,
  },
  data() {
    return {
      code: '',
      status: Status.INITIAL,
      networkErrorCode: '',
      errored: false,
      loading: true,
      Status,
      clientType: '',
    };
  },
  created() {
    this.code = this.$route.query.code as string;
    this.clientType = this.$route.query.clientType as string;
    this.$store.commit('updateQuizCode', this.code);
  },
  methods: {
    async goTo() {
      if (this.code === '' || this.code === undefined) {
        this.status = Status.EMPTY_INPUT;
      } else {
        this.$store.commit('updateLoading', true);

        try {
          const status = await QuizApi.check(this.code);
          if (status === Status.TEMP_SUBMITTED || status === Status.STARTED || status === Status.TRY_AGAIN) {
            this.$router.push({
              path: '/ing',
              query: {
                code: this.code,
                clientType: this.clientType,
              },
            });
          } else if (status === Status.SUBMMITED) {
            this.status = Status.SUBMMITED;
          }
        } catch (error: unknown) {
          const err = error as any;
          if (err.response.status === 404) {
            this.status = Status.NOT_EXISTING;
          } else {
            this.status = Status.NETWORK_ERROR;
            this.networkErrorCode = err.response.status;
          }
        }
        this.$store.commit('updateLoading', false);
      }
    },
  },
});
